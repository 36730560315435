
<template>
  <div class="recipeDetails">
    <div class="navbuttons">
      <div class="more-btn" @click="editRecipePane()">
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg">
          <defs>
            <path d="M0 0L24 0L24 24L0 24L0 0Z" id="path_1" />

          </defs>
          <g id="svg-10">
            <path d="M0 0L24 0L24 24L0 24L0 0Z" id="Background" fill="#FFFFFF" fill-opacity="0" fill-rule="evenodd"
              stroke="none" />
            <path
              d="M9.75 12C9.75 13.2405 10.7595 14.25 12 14.25C13.2405 14.25 14.25 13.2405 14.25 12C14.25 10.7595 13.2405 9.75 12 9.75C10.7595 9.75 9.75 10.7595 9.75 12L9.75 12Z"
              id="XMLID_294_" fill="currentColor" stroke="none" />
            <path
              d="M9.75 19.5C9.75 20.7405 10.7595 21.75 12 21.75C13.2405 21.75 14.25 20.7405 14.25 19.5C14.25 18.2595 13.2405 17.25 12 17.25C10.7595 17.25 9.75 18.2595 9.75 19.5L9.75 19.5Z"
              id="XMLID_295_" fill="currentColor" stroke="none" />
            <path
              d="M9.75 4.5C9.75 5.7405 10.7595 6.75 12 6.75C13.2405 6.75 14.25 5.7405 14.25 4.5C14.25 3.2595 13.2405 2.25 12 2.25C10.7595 2.25 9.75 3.2595 9.75 4.5L9.75 4.5Z"
              id="XMLID_297_" fill="currentColor" stroke="none" />
          </g>
        </svg>
      </div>
      <div @click="close()" class="button close-btn">

        <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg">
          <defs>
            <path d="M0 0L32 0L32 32L0 32L0 0Z" id="path_1" />
          </defs>
          <g id="svg-8">
            <path d="M0 0L32 0L32 32L0 32L0 0Z" id="Background" fill="#FFFFFF" fill-opacity="0" fill-rule="evenodd"
              stroke="none" />
            <path
              d="M13.1252 16.0007L23.2819 7.5374C24.1259 6.82846 24.2377 5.57045 23.532 4.72374C22.8262 3.87703 21.5686 3.76048 20.7193 4.46306L8.71877 14.4635C8.26324 14.8441 7.99997 15.4071 7.99997 16.0007C7.99997 16.5943 8.26324 17.1573 8.71877 17.5379L20.7193 27.5383C21.5684 28.2441 22.8287 28.1289 23.5357 27.2807C24.2426 26.4326 24.1291 25.1721 23.2819 24.464L13.1252 16.0007Z"
              id="Shape" fill="currentColor" stroke="none" />
          </g>
        </svg>


      </div>

    </div>


    <div class="recipe-image">
      <img :src="recipe.image" />
    </div>


    <div class="recipe-info">
      <h2 class="name"> {{ recipe.name }}</h2>
      <div class="heart-btn">
        <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg">
          <defs>
            <path d="M0 0L28 0L28 28L0 28L0 0Z" id="path_1" />

          </defs>
          <g id="svg-9">
            <path d="M0 0L28 0L28 28L0 28L0 0Z" id="Background" fill="#FFFFFF" fill-opacity="0" fill-rule="evenodd"
              stroke="none" />
            <path
              d="M13.9966 25.2311C13.8515 25.2311 13.7124 25.1734 13.6099 25.0709C12.1524 23.6135 10.7103 22.3236 9.31569 21.0762C7.21979 19.2017 5.24016 17.4311 3.82572 15.5885C2.22316 13.5009 1.47678 11.5034 1.47678 9.30229C1.47678 5.6998 4.4076 2.76894 8.01008 2.76894C11.0377 2.76894 12.6372 4.22532 13.9966 6.63649C15.3559 4.22532 16.9554 2.76894 19.983 2.76894C23.5893 2.76894 26.5233 5.6998 26.5233 9.30229C26.5233 11.5024 25.7768 13.4992 24.1741 15.586C22.7595 17.4279 20.7801 19.1974 18.6845 21.0709C17.2874 22.3199 15.8428 23.6113 14.3833 25.0709C14.2807 25.1734 14.1416 25.2311 13.9966 25.2311L13.9966 25.2311ZM8.01008 3.86269C5.01069 3.86269 2.57053 6.3029 2.57053 9.30229C2.57053 13.576 5.8689 16.526 10.0448 20.2609C11.3279 21.4086 12.6508 22.5918 13.9966 23.9142C15.3445 22.5897 16.67 21.4047 17.9555 20.2555C22.1313 16.5223 25.4296 13.5737 25.4296 9.30229C25.4296 6.3029 22.9863 3.86269 19.983 3.86269C17.4384 3.86269 16.0512 4.91936 14.4857 8.05022C14.3931 8.2355 14.2038 8.35253 13.9966 8.35253L13.9966 8.35253C13.7895 8.35253 13.6001 8.2355 13.5075 8.05022C11.9419 4.91936 10.5547 3.86269 8.01008 3.86269L8.01008 3.86269Z"
              id="_x31_66_x2C__Heart_x2C__Love_x2C__Like_x2C__Twitter" fill="currentColor" fill-rule="evenodd"
              stroke="none" />
          </g>
        </svg>
      </div>

      <div class="total"> {{ recipe.totalCalories }} calories</div>

      <div class="ingredients">
        <h4 class="title">ingredients</h4>

        <div v-for="ingredient in recipe.ingredients" class="ingredient">
          <!-- <img :src="ingredient.image"/> -->
          <!--   <div class="ingredient-image">
        </div> -->
          <div class="ingredient-name">
            <div id="checkbox">
              <input id="c1" type="checkbox">
              <!-- <label for="c1">Checkbox</label> -->
            </div>
            <span> {{ ingredient.name }}</span>

          </div>
          <div class="grams"> {{ parseInt(ingredient.grams, 10) }}g</div>
          <div class="calories"> {{ parseInt(ingredient.calculatedCals, 10) }} calories</div>
          <!-- {{ ingredient.nutrients.FIBTG }}g fiber -->

        </div>

      </div>

    </div>

    <BottomPane :id="'editRecipe'">
      <EditRecipe :recipe=recipe></EditRecipe>
    </BottomPane>

  </div>
</template>

<script>
import { animate, glide, spring, timeline, stagger } from "motion"

export default {
  name: 'RecipeDetails',
  updated() {
    if (this.recipe) {

      /*   animate(
        ".recipeDetails",
        { y:-100, opacity: 1 },
        { easing: glide({ velocity: -200 }) }
      )  */
      const sequence = [
        // [".recipeDetails", {  y:-100}, {  easing: spring({ velocity: 1000 }) }],
        [".recipeDetails", { y: [0, 100] }, { easing: glide({ velocity: -250, decay: 0.15 }) }],
        [".recipeDetails", { opacity: 1 }, { at: "<", easing: glide({ velocity: -250, decay: 0.15 }) }],
        [".recipe-image", { opacity: 1 }, { at: "<", easing: glide({ velocity: -250, decay: 0.15 }) }],
        [".recipe-info", { y: [0, 200] }, { at: "<", easing: glide({ velocity: -260, decay: 0.15 }) }],

      ]
      var animation = timeline(sequence, {});

      //  animation.playbackRate = -1
    }
  },
  mounted() {
    this.$nextTick(function () {
      if (this.recipe) {

        /*   animate(
          ".recipeDetails",
          { y:-100, opacity: 1 },
          { easing: glide({ velocity: -200 }) }
        )  */
        const sequence = [
          // [".recipeDetails", {  y:-100}, {  easing: spring({ velocity: 1000 }) }],
          [".recipeDetails", { y: [0, 100] }, { easing: glide({ velocity: -250, decay: 0.15 }) }],
          [".recipeDetails", { opacity: 1 }, { at: "<", easing: glide({ velocity: -250, decay: 0.15 }) }],
          [".recipe-image", { opacity: 1 }, { at: "<", easing: glide({ velocity: -250, decay: 0.15 }) }],
          [".recipe-info", { y: [0, 200] }, { at: "<", easing: glide({ velocity: -260, decay: 0.15 }) }],

        ]
        var animation = timeline(sequence, {});

        //  animation.playbackRate = -1
      }

    })
  },
  data() {
    return {
      animation: "",
      modalVisible: false
    }
  },
  props: ['recipe']
  ,
  components: {
    BottomPane: () => import("@/components/BottomPane.vue"),
    EditRecipe: () => import("@/components/Panes/EditRecipe.vue"),

  },
  methods: {

    editRecipePane() {
      console.log(this.recipe)
      let self = this;
    self.$panes['editRecipe'].setBreakpoints({
        top: {
          enabled: true,
          height: 840,
          bounce: true
        },
        middle: {
          enabled: true,
          height: 800,
          bounce: true
        },
        bottom: {
          enabled: false,
          height: 400,
          bounce: true
        }
      }); 
    
      setTimeout(function () {
        self.showPane('editRecipe', true);
            }, 150);
  /*     setTimeout(function () {
        self.$panes['editRecipe'].moveToBreak('top');
            }, 150); */

      const sequence = [
         ["#editRecipe", { y: [0, 100] }, {  }],
        ["#editRecipe", { opacity: 1 }, { at: "<", easing: glide({ velocity: -250, decay: 0.15 }) }],
        [".rendered", { opacity: 1 }, { at: "<", easing: glide({ velocity: -250, decay: 0.15 }) }],
      ]
      
   //var animation = timeline(sequence, {});




      /*   setTimeout(function () {
              self.$panes['editRecipe'].moveToHeight(705)
            }, 150);  */

    },
    close() {
      let self = this;
      const sequence = [
        // [".recipeDetails", {  y:-100}, {  easing: spring({ velocity: 1000 }) }],
        [".recipe-info", { y: [0, -200] }, { easing: glide({ velocity: 260, decay: 0.25 }) }],
        [".recipeDetails", { opacity: 0 }, { at: "<", easing: glide({ velocity: 250, decay: 0.20 }) }],
        [".recipe-image", { opacity: 0 }, { at: "<", easing: glide({ velocity: 250, decay: 0.1 }) }],
        [".recipe-image", { y: [0, -120] }, { at: "<", easing: glide({ velocity: 250, decay: 0.3 }) }],


      ]
      var animation = timeline(sequence, {});

      setTimeout(function () {
        self.$emit('closeDetails', 'true')
      }, 1500);



    },
    editRecipe() {
      /*  this.$store.commit('changeRecipe', recipe) */
    }
  },
}
</script>

<style lang="scss" scoped>
.navbuttons {
  width: -webkit-fill-available;
  height: 46px;
  display: flex;
  padding: 0px 20px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 10px;
  color: var(--gray-300);
  position: absolute;

}

.more-btn {
  display: flex;
  align-items: center;

  width: fit-content;
}

.close-btn {
  display: flex;
  align-items: center;

  svg {
    width: 24px;
  }
}

.heart-btn {
  position: absolute;
  right: 22px;
  top: 23px;
  color: var(--gray-300);
}

.title {
  color: var(--gray-700);


  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 2px 0px;

}

.ingredients {
  color: var(--gray-500);
  font-size: 13px;
  display: flex;
  gap: 6px;
  margin-top: 24px;
  flex-direction: column;
}

.ingredient {
  display: flex;
  gap: 12px;
  /* padding: 2px 0px; */
  justify-content: flex-start;
  /* width: 90%; */
}

.ingredient-name {
  font-weight: 600;
  color: var(--gray-700);
  text-transform: capitalize;
  width: -webkit-fill-available;
  gap: 12px;
  display: flex;
  align-items: center;

}

.grams {
  width: 20%;
  text-align: end;
  font-weight: 400;
}

.calories {
  width: 44%;
  line-break: auto;
  text-align: -webkit-right;
}

.name {
  color: var(--gray-800);
}

.total {
  color: var(--gray-500);
  font-size: 13px;
  /* background: var(--gray-50); */
  /* padding: 4px; */
  width: fit-content;
}

.recipe-info {
  position: fixed;
  bottom: -220px;
  height: 55vh;
  background: white;
  padding: 24px;
  width: -webkit-fill-available;
  box-shadow: 1px -16px 40px #8585851a;
  border-radius: 38px;

}

.recipeDetails {
  opacity: 0;
  background: white;
  height: 100vh;
  position: absolute;
  z-index: 9999;
  width: -webkit-fill-available;
  left: 0;
  position: fixed;
  bottom: -200px;
  background: var(--gray-50);
  padding-top: 59%;
  padding-top: 4vh;
  // height: -webkit-fill-available;
}

.ingredient-image {
  border: 3px solid yellow;
  height: 42vh;
  width: -webkit-fill-available;
  opacity: 0;
  /* padding: 20px; */
  display: flex;
  align-items: center;

}

.recipe-image {
  display: flex;
  /* background: red; */
  height: 42vh;
  align-items: center;
  justify-content: center;
}

.recipe-image img {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  object-fit: cover;
  width: 100%;
  height: 80%;
  object-fit: contain;
}
</style>

